import { insecureClientStorage } from 'src/utils/storage/insecure/insecure-client-storage'

const key = 'bwp-registrant-id'

export const registrantIdStorage = {
  get,
  set,
}

async function get(): Promise<string> {
  return insecureClientStorage.get(key)
}

async function set(registrantId: string) {
  await insecureClientStorage.set(key, registrantId)
}
