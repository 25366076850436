import { isValidElement, PropsWithChildren, ReactNode, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  CircleXIcon,
  getCheckboxFilterLabel,
  HStack,
  FilterButton,
  FilterDrawer,
  FilterMenu,
  Divider,
  VStack,
  Flex,
  Text,
  useBreakpoint,
  useDisclosure,
  StackProps,
} from 'src/components/designsystem'
import dayjs from 'dayjs'
import { OptionsOutlineIcon } from 'src/components/designsystem/Icons'
import { FilterSkeleton } from 'src/components/resource/FilterSkeleton'
import {
  countResponsiveSelectedFilters,
  countSelectedFilters,
  FiltersComponentProps,
  FiltersProps,
  FiltersWithProps,
  getFiltersWithProps,
  getOpenFilterIndexes,
  isCheckboxFilterProps,
  isDateFilterProps,
  isRangeFilterProps,
  isDynamicFilterOptionItem,
  isMoreFilterProps,
  MoreFiltersProps,
  isTextboxFilterProps,
} from 'src/utils/filters'
import useColumnSortDirection from 'src/components/resource/hooks/useColumnSortDirection'

export function MoreFilters({ children }: MoreFiltersProps) {
  return <>{children}</>
}

export function ToAccordionItem({ filter, props }: FiltersWithProps) {
  const moreFiltersWithProps = getFiltersWithProps(props['children'])

  if (!isValidElement(filter)) return

  // TODO: this shouldn't happen, and likely should be taken out
  if (isMoreFilterProps(props)) {
    return moreFiltersWithProps.map(ToAccordionItem)
  }

  const filterSelectedCount = countSelectedFilters(moreFiltersWithProps)

  const { filterId, label } = props as {
    filterId: string
    label: string
  }

  return (
    <AccordionItem key={filterId ?? label}>
      <AccordionButton>
        <Text
          fontSize="sm"
          fontWeight="bold"
          flex="1"
          textAlign="left"
          data-testid={`${label}-filter-dropdown`}
        >
          {filterSelectedCount > 0 ? `(${filterSelectedCount}) ${label}` : label}
        </Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel py={0} px={0}>
        {filter}
      </AccordionPanel>
    </AccordionItem>
  )
}

function RenderDesktopMoreFilters({
  moreFilters,
  footerOnClear,
}: {
  moreFilters: ReactNode
  footerOnClear: (filterIds: string[]) => void
}) {
  const filtersWithProps = getFiltersWithProps(moreFilters)

  if (filtersWithProps.length < 1) return null

  const filterSelectedCount = countSelectedFilters(filtersWithProps)

  return (
    <FilterMenu
      label={filterSelectedCount > 0 ? `(${filterSelectedCount}) More` : 'More'}
      title="More"
      isSelected={filterSelectedCount > 0}
      footerOnClear={() =>
        footerOnClear &&
        footerOnClear(
          filtersWithProps.reduce<string[]>((filterIds, { props }) => {
            if (isDateFilterProps(props) || isCheckboxFilterProps(props)) {
              return [...filterIds, props.filterId]
            } else return filterIds
          }, [])
        )
      }
    >
      <OverflowStack direction="column" alignItems="flex-start" spacing={0} divider={<Divider />}>
        {filtersWithProps.map(({ filter, props }) => {
          return (
            <Flex key={props.label} direction="column" alignItems="flex-start">
              <Text
                textAlign="left"
                pt={4}
                pb={0}
                px={4}
                fontWeight="bold"
                textTransform="uppercase"
                fontSize="sm"
              >
                {props.label}
              </Text>
              {filter}
            </Flex>
          )
        })}
      </OverflowStack>
    </FilterMenu>
  )
}

function DesktopFilters<T extends Record<string, any>>({
  anyIsSelected,
  allSelectedFilters,
  setAllSelectedFilters,
  filtersWithProps,
  resetSelectedFilters,
}: FiltersComponentProps<T>) {
  const { update: updateSort, direction: sortDirection } = useColumnSortDirection()
  const isSortActive = sortDirection !== null

  return (
    <HStack spacing={4}>
      {filtersWithProps?.map(({ filter, props: filterProps }) => {
        if (!isValidElement(filter)) return null

        if (isMoreFilterProps(filterProps)) {
          return (
            <RenderDesktopMoreFilters
              key="more-filters"
              moreFilters={filterProps.children}
              footerOnClear={(filterIds) => {
                if (!Array.isArray(filterIds) || filterIds.length < 1) return

                const newSelected = { ...allSelectedFilters }
                // const childrenWithProps = getFiltersWithProps(filterProps.children)

                filterIds.forEach((id) => delete newSelected[id])
                Object.keys(newSelected).forEach((filterId) => {
                  if (isDynamicFilterOptionItem(newSelected[filterId])) {
                    delete newSelected[filterId]
                  }
                })
                setAllSelectedFilters(newSelected)
              }}
            />
          )
        }

        const { label, filterId } = filterProps

        let title = label
        let isSelected = false
        let text = label

        if (isCheckboxFilterProps(filterProps) && filterProps.values) {
          title = filterProps.values.map((v) => v.name).join(', ')
          isSelected = filterProps.values.length > 0
          const selectedNames = isSelected ? filterProps.values.map((option) => option.name) : null
          text = getCheckboxFilterLabel(selectedNames, label)
        }

        if (isDateFilterProps(filterProps) && filterProps.value) {
          const dates = filterProps.value
          if (dates.from) {
            title = text = `${dayjs(dates.from).format('MM/DD/YY')}`
            if (dates.from !== dates.to) {
              title = text = `${dayjs(dates.from).format('MM/DD/YY')}—${dayjs(dates.to).format(
                'MM/DD/YY'
              )}`
            }
            isSelected = true
          }
        }

        if (isRangeFilterProps(filterProps) && filterProps.value) {
          const amount = filterProps.value
          if (amount.low) {
            title = text = `$${amount.low} or more`
            if (amount.high && amount.low !== amount.high) {
              title = text = `$${amount.low}—$${amount.high}`
            }
            isSelected = true
          } else if (amount.high) {
            title = text = `$${amount.high} or less`
            isSelected = true
          }
        }

        if (isTextboxFilterProps(filterProps) && filterProps.value.value) {
          if (filterProps.value) {
            title = text = filterProps.value.value
            isSelected = true
          }
        }

        return (
          <FilterMenu
            key={filterId}
            label={text}
            title={title}
            isSelected={isSelected}
            footerOnClear={() => {
              const mostFilters = { ...allSelectedFilters }
              delete mostFilters[filterId]
              setAllSelectedFilters(mostFilters)
            }}
          >
            {filter}
          </FilterMenu>
        )
      })}

      {(anyIsSelected || isSortActive) && (
        <Button
          variant="ghostDestructive"
          data-testid="filter-clear-all-button"
          fontWeight="normal"
          size="sm"
          borderRadius="full"
          onClick={(event) => {
            resetSelectedFilters(event)
            updateSort(null, null)
          }}
        >
          <CircleXIcon mr={1} />
          Clear all
        </Button>
      )}
    </HStack>
  )
}

type ExpandedIndex = number | number[]

function ResponsiveFilters<T extends Record<string, any>>({
  allSelectedFilters,
  setAllSelectedFilters,
  filtersWithProps,
  resetSelectedFilters,
}: FiltersComponentProps<T>) {
  const disclosure = useDisclosure()
  const [openIndexes, setOpenIndexes] = useState<ExpandedIndex>(() =>
    getOpenFilterIndexes(allSelectedFilters, filtersWithProps)
  )
  const count = countResponsiveSelectedFilters(allSelectedFilters)
  const hasSelected = count > 0
  const filtersLabel = hasSelected ? `(${count}) Filters` : 'Filters'
  const setButtonLabel = hasSelected ? `Set (${count})` : 'Set'

  return (
    <>
      <HStack pl={[2, null, 0]}>
        <FilterButton
          variant={hasSelected ? 'selected' : 'unselected'}
          icon={<OptionsOutlineIcon fontSize="xl" />}
          onClick={disclosure.onOpen}
        >
          {filtersLabel}
        </FilterButton>
        {hasSelected && (
          <Button
            variant="ghostDestructive"
            data-testid="filter-clear-all-button"
            fontWeight="normal"
            size="sm"
            borderRadius="full"
            onClick={resetSelectedFilters}
          >
            <CircleXIcon mr={1} />
            Clear all
          </Button>
        )}
      </HStack>
      <FilterDrawer
        disclosure={disclosure}
        footerOnClear={setAllSelectedFilters}
        footerOnSet={() => {
          setAllSelectedFilters(allSelectedFilters)
          disclosure.onClose()
        }}
        setButtonLabel={setButtonLabel}
      >
        <Accordion
          allowMultiple
          width="full"
          index={openIndexes}
          onChange={(indexes) => setOpenIndexes(indexes)}
        >
          {filtersWithProps.map(ToAccordionItem)}
        </Accordion>
      </FilterDrawer>
    </>
  )
}

export interface OverflowStackProps extends StackProps {
  isScrollable?: boolean
}

export function OverflowStack({
  isScrollable = true,
  children,
  ...props
}: PropsWithChildren<OverflowStackProps>) {
  const { isDesktop } = useBreakpoint()
  return (
    <VStack
      overflowY="auto"
      maxH={isDesktop && isScrollable && '50vh'}
      css={
        isDesktop &&
        isScrollable && {
          '&::-webkit-scrollbar': {
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar:vertical': {
            width: '12px',
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '11px',
            border: '2px solid white',
          },
        }
      }
      {...props}
    >
      {children}
    </VStack>
  )
}

/**
 * The filters component takes care of the
 * @param props -
 */
export function Filters<T extends Record<string, any>>(props: PropsWithChildren<FiltersProps<T>>) {
  const { isDesktop } = useBreakpoint()
  const { setAllSelectedFilters } = props
  const resetSelectedFilters = useCallback(() => setAllSelectedFilters(), [setAllSelectedFilters])
  const filterProps = {
    ...props,
    resetSelectedFilters,
    anyIsSelected: props.allSelectedFilters && Object.keys(props.allSelectedFilters).length > 0,
    filtersWithProps: getFiltersWithProps(props.children),
  }

  if (filterProps.isLoading && filterProps.skeletonCount > 0) {
    return <FilterSkeleton filterLength={isDesktop ? filterProps.skeletonCount : 1} />
  }

  return isDesktop ? (
    <DesktopFilters {...filterProps} />
  ) : (
    // Only render the responsive filters if there are children (the consumer knows what they're doing)
    filterProps.filtersWithProps.length > 0 && <ResponsiveFilters {...filterProps} />
  )
}
