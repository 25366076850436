import { queryOptions, useQuery } from '@tanstack/react-query'
import * as api from 'src/api'

export function useGetCommoditySubgroups() {
  return useQuery({
    queryKey: ['get-commodity-subgroups'],
    queryFn: () => api.aggregatorService.getCommoditySubgroups(),
  })
}

export function useFindOrCreateTenantBySlug() {
  return useQuery({
    queryKey: ['find-or-create-tenant-by-slug'],
    queryFn: () => api.aggregatorService.findOrCreateTenantBySlug(),
  })
}

export const getAggregatorConfigQueryOptions = queryOptions({
  queryKey: ['find-or-create-tenant-config-by-slug'],
  queryFn: () => api.aggregatorService.findOrCreateTenantConfigBySlug(),
})

export function useFindOrCreateTenantConfigBySlug() {
  return useQuery({
    ...getAggregatorConfigQueryOptions,
    select: (data) => data.data,
  })
}

export function useGetActiveUsersFromIdentifiers(ids: string[]) {
  return useQuery({
    queryKey: ['get-active-users-from-identifiers', ids],
    queryFn: () => api.aggregatorService.getActiveUsersFromIdentifiers(ids),
    enabled: ids?.filter(Boolean)?.length > 0,
  })
}
