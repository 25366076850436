import { Box, BoxProps as ChakraBoxProps, useBreakpointValue } from '@chakra-ui/react'

type HeadingTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export interface HeadingProps extends ChakraBoxProps {
  type?: HeadingTypes | HeadingTypes[]
}

export const Heading = ({ type = 'h2', children, ...props }: HeadingProps) => {
  const responsiveType = useBreakpointValue(Array.isArray(type) ? type : [type])
  return (
    <Box as={responsiveType} {...props}>
      {children}
    </Box>
  )
}
