import { useState } from 'react'
import getClient from 'src/utils/clients/get-client'
import useOnce from 'src/utils/hooks/use-once'
import { appPluginWrapper } from 'src/utils/clients/native/wrappers/app-plugin-wrapper'

export function useNativeAppVersion(): string | undefined {
  const [nativeAppVersion, setNativeAppVersion] = useState<string>()

  useOnce(async () => {
    if (getClient().isNativeApp) {
      const appVersion = await getNativeAppVersion()
      setNativeAppVersion(appVersion)
    }
  })
  return nativeAppVersion
}

export async function getNativeAppVersion(): Promise<string> | undefined {
  if (!getClient().isNativeApp) return

  const appInfo = await appPluginWrapper.getInfo()
  return appInfo.version
}
