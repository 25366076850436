import { Box, HStack, Skeleton } from 'src/components/designsystem'

interface FilterSkeletonProps {
  filterLength: number
}

export function FilterSkeleton({ filterLength }: FilterSkeletonProps) {
  const filterCount = filterLength > 4 ? 4 : filterLength

  return (
    <Box height="40px">
      <HStack spacing={4}>
        {Array.from({ length: filterCount }).map((element, i) => (
          <Skeleton key={`filter-skeleton-${i}`} height="32px" rounded="3xl" width="108px" />
        ))}
      </HStack>
    </Box>
  )
}
