import { useEffect, useCallback, PropsWithChildren, ReactNode } from 'react'
import {
  Box,
  Flex,
  Collapse,
  Divider,
  Button,
  Heading,
  HStack,
  useBreakpoint,
} from 'src/components/designsystem'
import DisplayConfig, {
  DisplayConfig as DisplayConfigType,
} from 'src/components/designsystem/display-config'
import ResourceListItemActionMenu from 'src/components/resource/ResourceListItemActionMenu'
import ToggleButton from 'src/components/resource/ToggleButton'
import ActionMenuHeader from 'src/components/resource/ActionMenuHeader'
import { trackEvent } from 'src/utils/analytics'
import { showDivider } from './helpers'

export interface ResourceListItemProps<Item> {
  item: Item
  columnConfig: DisplayConfigType<Item>
  detailSecondaryTitle?: JSX.Element
  detailPrimaryTitle: string | JSX.Element
  customRowComponent?: { Component: React.FC<{ item: Item }>; props?: Record<string, any> }
  mobileFooter?: JSX.Element
  isOpen?: boolean
  analyticsTitle?: string
  onToggle?: () => void
  onClose?: () => void
  setDetailItem?: (item: Item) => void
  actionMenuConfig?: { key: string; render: (item: any) => ReactNode }[]
  actionMenuModalSetItem?: (item: Item) => void
  actionMenuModalOnOpen?: () => void
}

export default function ResourceListItem<Item>({
  item,
  columnConfig,
  detailSecondaryTitle,
  detailPrimaryTitle,
  customRowComponent: CustomRowComponent,
  mobileFooter,
  isOpen,
  onToggle,
  onClose,
  setDetailItem,
  analyticsTitle,
  children,
  actionMenuConfig,
  actionMenuModalSetItem,
  actionMenuModalOnOpen,
}: PropsWithChildren<ResourceListItemProps<Item>>) {
  const { isDesktop } = useBreakpoint()
  const isExpandable = !!children
  const hasCustomRowComponent = !!CustomRowComponent

  useEffect(() => {
    if (!isExpandable) return
    if (isOpen && !isDesktop) onClose()
  }, [isOpen, isDesktop, onClose, isExpandable])

  // Only toggle the row if nothing on the page is currently selected
  const onHeaderClick = useCallback(() => {
    if (window.getSelection().toString()?.length !== 0) return
    onToggle()
    trackEvent(analyticsTitle, `Detail ${!isOpen ? 'Expand' : 'Collapse'}`)
  }, [onToggle, analyticsTitle, isOpen])

  const onMobileDetailsClick = useCallback(() => {
    if (setDetailItem) {
      setDetailItem(item)
      trackEvent(analyticsTitle, 'Mobile Detail Open')
    }
  }, [item, analyticsTitle, setDetailItem])

  return (
    <>
      <Box layerStyle="card-small" w="100%" bg="white" pb={isOpen ? 12 : 0}>
        <Flex
          w="100%"
          minH={isDesktop ? '72px' : 0}
          align="center"
          direction={['column', null, null, 'row']}
          {...(isDesktop && isExpandable
            ? {
                onClick: onHeaderClick,
                cursor: 'pointer',
              }
            : { onClick: hasCustomRowComponent ? onMobileDetailsClick : undefined })}
          px={[4, null, 6]}
          py={[4, null, null, 3]}
        >
          {hasCustomRowComponent && (
            <CustomRowComponent.Component item={item} {...(CustomRowComponent.props ?? {})} />
          )}
          {!hasCustomRowComponent && (
            <>
              {isExpandable && (
                <Flex mr={6} display={['none', null, null, 'flex']}>
                  <ToggleButton isOpen={isOpen} />
                </Flex>
              )}

              {!isDesktop && (
                <ActionMenuHeader
                  title={detailPrimaryTitle}
                  actionMenuConfig={actionMenuConfig}
                  actionMenuModalSetItem={actionMenuModalSetItem}
                  actionMenuModalOnOpen={actionMenuModalOnOpen}
                  item={item}
                />
              )}

              {!isExpandable || !isOpen ? (
                <>
                  <ResourceListRow
                    actionMenuConfig={actionMenuConfig}
                    actionMenuModalSetItem={actionMenuModalSetItem}
                    actionMenuModalOnOpen={actionMenuModalOnOpen}
                    columnConfig={columnConfig}
                    item={item}
                  />

                  <Flex
                    display={['flex', null, null, 'none']}
                    justify={['center', null, 'flex-end']}
                    w="100%"
                    pt={6}
                  >
                    {mobileFooter}
                    {!mobileFooter && mobileFooter !== null && isExpandable && (
                      <Box>
                        <Button
                          size="sm"
                          variant="outline"
                          minW="100px"
                          onClick={onMobileDetailsClick}
                          data-testid="view-details-button"
                        >
                          View detail
                        </Button>
                      </Box>
                    )}
                  </Flex>
                </>
              ) : (
                <HStack w="100%" alignItems="center" justifyContent="space-between">
                  <Heading aria-label="Id" type="h4" mr="auto" data-testid="detail-primary-header">
                    {detailPrimaryTitle}
                  </Heading>
                  {detailSecondaryTitle}
                </HStack>
              )}
            </>
          )}
        </Flex>

        {isExpandable && (
          <Collapse in={isOpen} unmountOnExit>
            <Divider />
            <Box px={[4, null, 6]}>{children}</Box>
          </Collapse>
        )}
      </Box>
    </>
  )
}

export interface ResourceListRowProps<Item> {
  item: Item
  columnConfig: DisplayConfigType<Item>
  actionMenuConfig?: { key: string; render: (item: Item) => ReactNode }[]
  actionMenuModalSetItem?: (item: Item) => void
  actionMenuModalOnOpen?: () => void
}

function ResourceListRow<Item>({
  actionMenuConfig,
  actionMenuModalSetItem,
  actionMenuModalOnOpen,
  columnConfig,
  item,
}: ResourceListRowProps<Item>) {
  const { breakpoint, isDesktop } = useBreakpoint()
  const configItemLength = columnConfig.items.length

  const showDividerFn = useCallback(
    ({ index }) => showDivider({ index, breakpoint, configItemLength }),
    [breakpoint, configItemLength]
  )

  return (
    <DisplayConfig.BodyRow
      w="100%"
      templateColumns={[
        '1fr',
        null,
        '1fr 1fr',
        columnConfig.desktopTemplateColumns(columnConfig.items),
      ]}
      columnGap={[12, null, null, 0]}
      alignItems={['center', 'initial', null, 'center']}
    >
      <DisplayConfig.RowRenderer
        displayConfig={columnConfig.items}
        item={item}
        showDivider={showDividerFn}
      />

      {isDesktop && actionMenuConfig?.length > 0 && (
        <ResourceListItemActionMenu
          actionMenuConfig={actionMenuConfig}
          actionMenuModalSetItem={actionMenuModalSetItem}
          actionMenuModalOnOpen={actionMenuModalOnOpen}
          item={item}
        />
      )}
    </DisplayConfig.BodyRow>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////
