import { NextApiRequest, NextApiResponse } from 'next'
import NextAuth, { NextAuthOptions, Session } from 'next-auth'
import { JWT } from 'next-auth/jwt'
import KeycloakProvider from 'next-auth/providers/keycloak'
import {
  TOKEN_EXPIRY_REFRESH_SECS,
  TOKEN_REFRESH_ERROR_CODE,
  getSecondsUntilTokenExpires,
  logOutKeycloakSession,
} from 'src/auth/keycloak'
import { ENV, SERVER_ENV, handleSsrRedirect, logger } from 'src/utils'

export type SessionPayload = Session & {
  user?: any
  accessToken?: string
  error?: any
}

const NEXTAUTH_DEBUG = SERVER_ENV.NEXTAUTH_DEBUG
const CLIENT_ID = SERVER_ENV.OAUTH_CLIENT_ID
const CLIENT_SECRET = SERVER_ENV.OAUTH_CLIENT_SECRET
const ISSUER = ENV.OAUTH_ISSUER

export const authOptions: NextAuthOptions = {
  debug: NEXTAUTH_DEBUG,

  providers: [
    KeycloakProvider({
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      issuer: ISSUER,
      authorization: {
        params: { scope: 'openid' },
      },
    }),
  ],
  session: {
    strategy: 'jwt',
  },
  events: {
    async signOut({ token }) {
      if (token?.idToken) {
        logger.debug({
          message: 'Logging out users keycloak session',
          context: { idToken: token.idToken },
        })
        await logOutKeycloakSession(token.idToken)
      }
    },
  },
  callbacks: {
    async jwt({ token, user, account }) {
      if (account && user) {
        // The `account` and `user` args are only supplied on initial sign in
        const accessTokenExpires = account?.expires_at ?? null
        const bushelId = parseBushelId(token?.sub)

        // An encrypted JWT of this data is stored in the user's cookies
        const newToken: JWT = {
          idToken: account.id_token,
          accessToken: account.access_token,
          accessTokenExpires,
          refreshToken: account.refresh_token,
          user: {
            id: user.id,
            name: user.name,
            email: user.email,
            bushelId,
          },
        }

        return newToken
      }

      if (!token.accessTokenExpires) {
        return token
      }

      const expiresInSecs = getSecondsUntilTokenExpires(token.accessTokenExpires)

      logger.debug({
        message: '[next-auth] callbacks.jwt - checking access token freshness',
        context: { expiresInSecs, accessTokenExpires: token.accessTokenExpires },
      })

      if (expiresInSecs > TOKEN_EXPIRY_REFRESH_SECS) {
        return token
      }

      logger.debug({ message: '[next-auth] callbacks.jwt - refreshing access token' })

      return refreshAccessToken(token)
    },
    async session({ session, token }) {
      // logger.debug({ message: '[next-auth] callbacks.session - returning session', context: {} })

      session.accessToken = token?.accessToken ?? null
      session.idToken = token?.idToken ?? null
      session.user = token?.user ?? null
      session.error = token?.error ?? null

      return session
    },
  },
  pages: {
    signIn: '/auth/signin',
  },
}

export const parseBushelId = (bushelId?: string) => {
  if (!bushelId) return null
  try {
    return bushelId.split(':')[2]
  } catch (error) {
    logger.error({ message: 'Error parsing bushelId', error, context: { bushelId } })
    return null
  }
}

async function refreshAccessToken(token: JWT) {
  try {
    if (!token?.refreshToken) throw new Error('Refresh token not found')
    if (!CLIENT_ID || !CLIENT_SECRET || !ISSUER) {
      throw new Error('Keycloak is not configured properly')
    }

    const url = `${ISSUER}/protocol/openid-connect/token`
    const body = new URLSearchParams()
    body.append('client_id', CLIENT_ID)
    body.append('client_secret', CLIENT_SECRET)
    body.append('grant_type', 'refresh_token')
    body.append('refresh_token', token.refreshToken)

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body,
    })

    const refreshedTokens = await response.json()

    if (!response.ok) {
      throw refreshedTokens
    }

    const accessTokenExpires = Math.round(Date.now() / 1000 + refreshedTokens.expires_in)

    return {
      ...token,
      accessToken: refreshedTokens.access_token,
      accessTokenExpires,
      refreshToken: refreshedTokens.refresh_token ?? token.refreshToken, // Fall back to old refresh token
      error: null,
    }
  } catch (error) {
    logger.warn({
      message: '[next-auth] callbacks.jwt - unable to refresh token',
      error,
      context: {
        accessTokenExpires: token?.accessTokenExpires,
        user: token?.user,
      },
    })

    return {
      ...token,
      error: TOKEN_REFRESH_ERROR_CODE,
    }
  }
}

/**
 * An alternative way of exporting this API handler is: `export default NextAuth(authOptions)`
 *
 * Wrapping it this way gives us access to the `req` and `res` objects prior to handing off to next-auth.
 */
export default async function auth(req: NextApiRequest, res: NextApiResponse) {
  if (req.query?.error === 'access_denied') {
    return handleSsrRedirect({ req, res, path: '/', props: null })
  }
  return NextAuth(authOptions)(req, res)
}
