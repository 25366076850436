import ky from 'ky'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import { ENV, isServer } from 'src/utils'

const { token } = isServer() ? ({} as any) : window.__NEXT_DATA__?.props || {}

export let reportingConfigServiceClient = ky.create({
  prefixUrl: ENV.BIDL_API + '/api/reporting',
  retry: 0,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined,
  },
  timeout: 120000, //2 mins
  hooks: {
    beforeRequest: [handleRefreshTokenIfExpired],
    afterResponse: [
      async (_request, _options, response) => {
        if (!response.ok) {
          try {
            const body = await response.text()

            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
              body,
            })

            response.payload = JSON.parse(body)
          } catch (err) {
            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
            })
          }
        }

        return response
      },
    ],
  },
})

// UTILS
///////////////////////////////////////////////////////////////////////////////////////////////////

export const updateReportingConfigServiceClient = (config = {}) => {
  reportingConfigServiceClient = reportingConfigServiceClient.extend(config)
}

export const updateReportingConfigServiceToken = (token?: string) => {
  updateReportingConfigServiceClient({
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
  })
}
