import { Box, Flex, Button, useBreakpoint, ShareIcon } from 'src/components/designsystem'
import { ContractFilters } from './ContractFilters'
import { useFilters } from 'src/data/filters'
import { useContractFilterOptions } from 'src/data/queries'

type ContractsFilterQuery = ReturnType<typeof useContractFilterOptions>
type FiltersHook = ReturnType<typeof useFilters>

interface ContractHeaderProps {
  filterOptions: ContractsFilterQuery['data']
  selectedFilters: FiltersHook['selectedFilters']
  setAllSelectedFilters: FiltersHook['setAllSelectedFilters']
  setFilterById: FiltersHook['setFilterById']
  showModal: () => void
  isExportDisabled: boolean
  isFiltersLoading: boolean
}

export default function ContractHeader({
  filterOptions,
  selectedFilters,
  setAllSelectedFilters,
  setFilterById,
  showModal,
  isExportDisabled,
  isFiltersLoading,
}: ContractHeaderProps) {
  const { isMobile } = useBreakpoint()

  return (
    <Flex h={10} mt={[2, null, 4, 8]} justify="space-between" alignItems="center">
      <Box>
        <ContractFilters
          filterOptions={filterOptions}
          allSelectedFilters={selectedFilters}
          setAllSelectedFilters={setAllSelectedFilters}
          setFilterById={setFilterById}
          isLoading={isFiltersLoading}
        />
      </Box>

      <Button
        variant={isMobile ? 'ghost' : 'primary'}
        isDisabled={isExportDisabled}
        onClick={showModal}
        leftIcon={<ShareIcon fontSize="xl" />}
      >
        Export
      </Button>
    </Flex>
  )
}
