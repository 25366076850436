import { aggregatorClient } from 'src/api/aggregator/client'

export const accountPayableBaseUrl = 'api/aggregator/accountpayablebalance'

export const accountPayableBalanceRequests = {
  listAccountPayableBalances: (options?: ListAccountPayableBalancesOptions) => {
    return aggregatorClient
      .post(`${accountPayableBaseUrl}/v1/GetAccountPayableBalances`, {
        json: options,
      })
      .json<AccountPayableBalanceResponse>()
  },
}
