import dayjs from 'dayjs'

export default function buildPaymentSearchParams(
  { page = null, size = null, filter = null, sort = null, summaryDates = null },
  deferredVersion = null
) {
  //have to use URLSearchParams instead of normal obj, because querySting keys may need to be repeated for the payments api
  const searchParams = new URLSearchParams()

  // kinda weird conditionals here due to inconsistency in payment API params...
  if (page?.page) {
    searchParams.append('page.number', (page?.page - 1).toString())
  }
  if (page?.size) {
    searchParams.append('page.size', page?.size)
  }
  if (!(page?.page || page?.size)) {
    if (page) {
      searchParams.append('page', (page - 1).toString())
    }
    if (size) {
      searchParams.append('size', size)
    }
  }

  if (filter) {
    if (filter.dates) {
      if (deferredVersion) {
        if (deferredVersion === 'v1') {
          searchParams.append(
            'filter.deferredDate.after',
            dayjs(filter.dates.from).add(-1, 'day').format('YYYY-MM-DD')
          )
          searchParams.append(
            'filter.deferredDate.before',
            dayjs(filter.dates.to).add(1, 'day').format('YYYY-MM-DD')
          )
        }
        if (deferredVersion === 'v2') {
          searchParams.append(
            'filter.deferredDate.after',
            dayjs(filter.dates.from).add(-1, 'day').toISOString()
          )
          searchParams.append(
            'filter.deferredDate.before',
            dayjs(filter.dates.to).add(1, 'day').toISOString()
          )
        }
      } else {
        searchParams.append(
          'filter.date.after',
          dayjs(filter.dates.from).set('hour', 0).set('minute', 0).set('second', 0).toISOString()
        )
        searchParams.append(
          'filter.date.before',
          dayjs(filter.dates.to).set('hour', 23).set('minute', 59).set('second', 59).toISOString()
        )
      }
    }
    if (filter.origin) {
      searchParams.append('filter.origin', filter.origin)
    }
    if (filter.amount?.low) {
      searchParams.append('filter.amount.greater', filter.amount.low)
    }
    if (filter.amount?.high) {
      searchParams.append('filter.amount.less', filter.amount.high)
    }

    if (filter.commodities) {
      if (Array.isArray(filter.commodities)) {
        filter.commodities.forEach((value) => {
          searchParams.append('filter.commodities', value)
        })
      } else {
        searchParams.append('filter.commodities', filter.commodities)
      }
    }

    if (filter.locations) {
      if (Array.isArray(filter.locations)) {
        filter.locations.forEach((value) => {
          searchParams.append('filter.locations', value)
        })
      } else {
        searchParams.append('filter.locations', filter.locations)
      }
    }

    // is filter.paymentType for deferred payments
    if (filter.type) {
      filter.type.map((item) => searchParams.append('filter.type', item))
    }
    if (filter.entry) {
      searchParams.append('filter.entry', filter.entry)
    }

    if (filter.paymentNumber) {
      searchParams.append('filter.paymentNumber', filter.paymentNumber?.value)
    }

    if (filter.processed !== undefined) {
      searchParams.append('filter.processed', filter.processed)
    }

    if ('completedAt' in filter) {
      searchParams.append(
        'filter.completedAt.after',
        dayjs(filter.completedAt.from).startOf('day').toISOString()
      )
      searchParams.append(
        'filter.completedAt.before',
        dayjs(filter.completedAt.to).endOf('day').toISOString()
      )
    }

    if ('destinationUserIds' in filter) {
      searchParams.append('filter.destinationUserIds', filter.destinationUserIds)
    }

    if ('sourceUserIds' in filter) {
      searchParams.append('filter.sourceUserIds', filter.sourceUserIds)
    }

    if ('status' in filter) {
      searchParams.append('filter.status', filter.status)
    }
  }

  if (summaryDates) {
    searchParams.append('filter.date.after', summaryDates.from)
    searchParams.append('filter.date.before', summaryDates.to)
  }

  if (sort?.enabled !== undefined) {
    searchParams.append('sort.enabled', sort?.enabled)
    if (sort?.field) {
      searchParams.append('sort.field', sort?.field)
    }
    if (sort?.direction) {
      searchParams.append('sort.direction', sort?.direction)
    }
  } else if (sort) {
    searchParams.append('sort', sort)
  }

  return searchParams
}
