import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { useMerchandiser } from 'src/data/merchandiser'
import {
  DEFAULT_PAGE_SIZE,
  flattenPages,
  getAggregatorNextPageParamFactory,
} from 'src/data/queries/utils'

const businessLocationsOptions = ({
  pageSize = DEFAULT_PAGE_SIZE,
  selectedUserIdpId,
}: {
  selectedUserIdpId?: string
  pageSize?: number
}) =>
  infiniteQueryOptions({
    queryKey: ['business-locations', { selectedUserIdpId, pageSize }],
    queryFn: ({ pageParam: token }) =>
      aggregatorService.getBusinessLocations({
        pagination: { token, pageSize },
      }),
    select: flattenPages,
    initialPageParam: null,
    getNextPageParam: getAggregatorNextPageParamFactory(pageSize),
    meta: {
      errorMessage:
        'Unable to retrieve business locations list, please refresh the page to try again.',
    },
  })

type BusinessLocationsOptions = ReturnType<typeof businessLocationsOptions>

export function useBusinessLocations(options?: Partial<BusinessLocationsOptions>) {
  const { selectedUserIdpId } = useMerchandiser()

  return useInfiniteQuery({
    ...businessLocationsOptions({ selectedUserIdpId }),
    ...options,
  })
}
