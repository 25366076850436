import {
  updateAggregatorToken,
  updateAnalyticsToken,
  updateCentreToken,
  updateFuturesServiceToken,
  updatePaymentsToken,
  updateReportingConfigServiceToken,
  updateReportingServiceToken,
} from 'src/api'

// Updates token header across all http clients. Add new http clients here as needed.
export function updateAllAuthTokens(token: string | null) {
  updateCentreToken(token)
  updateReportingServiceToken(token)
  updateReportingConfigServiceToken(token)
  updateFuturesServiceToken(token)
  updatePaymentsToken(token)
  updateAnalyticsToken(token)
  updateAggregatorToken(token)
}
