import { useMemo } from 'react'
import { useFindOrCreateTenantConfigBySlug } from 'src/data/queries/config'

export default function useAggregatorConfigCheck(keyName: string, version?: string) {
  const { isLoading, data: configs } = useFindOrCreateTenantConfigBySlug()
  const foundConfig = useMemo(
    () => configs?.find((config) => config.key === keyName),
    [keyName, configs]
  )

  return {
    isLoading,
    isConfigEnabled: !!foundConfig && foundConfig?.value !== 'null',
    isVersionMatching: (version && foundConfig?.value === version) || !version,
  }
}
