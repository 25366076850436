import {
  VStack,
  Img,
  BoxProps,
  Text,
  FormLabel,
  FormControl,
  Select,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react'
import { Heading } from 'src/components/designsystem/Heading'
import * as NonIdealStates from 'src/components/resource/NonIdealStates'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from 'src/auth'
import { useFailedLoginReporting } from 'src/data/failed-login'
import { useCompany } from 'src/data/use-company'
import { useCashBidsLocations } from 'src/data/queries/cash-bids'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AnimatedFormErrorMessage from 'src/components/admin/payments/AnimatedFormErrorMessage'

export interface NonIdealStateProps extends BoxProps {
  illustration:
    | '404'
    | 'binoculars'
    | 'error-coffee'
    | 'not-found'
    | 'search-user'
    | 'tumbleweed'
    | 'pig'
  illustrationAltText?: string
  illustrationWidth?: string | string[]
  size?: 'sm' | 'lg'
  header?: string | React.ReactNode
  subHeader?: string | React.ReactNode
  displayImage?: string | React.ReactNode
  action?: string | React.ReactNode
  isVerticallyCentered?: boolean
}

interface FormValues {
  name: string
  location: number
}

const noAccountsFormSchema = yup
  .object({
    name: yup
      .string()
      .required('Please enter your name')
      .max(256, 'Name cannot exceed 256 characters'),
    location: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Please select a location'),
  })
  .required()

export const GenericNonIdealState: React.FC<NonIdealStateProps> = ({
  illustration,
  illustrationAltText = '',
  illustrationWidth,
  size = 'lg',
  header,
  subHeader,
  action,
  isVerticallyCentered = false,
  ...props
}) => {
  const isSmall = size === 'sm'
  return (
    <VStack
      alignItems="center"
      width="100%"
      spacing={isSmall ? null : 8}
      mt={isVerticallyCentered ? '15vh' : undefined}
      {...props}
    >
      <Img
        src={`/img/illustrations/${illustration}.svg`}
        width={illustrationWidth ?? (isSmall ? '150px' : ['260px', null, '450px'])}
        alt={illustrationAltText}
        mb={isSmall ? 6 : null}
      />

      <Heading px={isSmall ? null : [2, null, 0]} textAlign="center" type={isSmall ? 'h6' : 'h3'}>
        {header}
      </Heading>

      <Text maxW="420px" px={[2, null, 0]} textAlign="center">
        {subHeader}
      </Text>

      {action}
    </VStack>
  )
}

type NoAccountsFormPropsRequired = Required<
  Pick<
    NonIdealStateProps,
    'illustration' | 'illustrationAltText' | 'header' | 'subHeader' | 'action'
  >
>

type NoAccountsFormPropsOptional = Pick<
  NonIdealStateProps,
  'isVerticallyCentered' | 'size' | 'illustrationWidth'
>

export type NoAccountsFormProps = NoAccountsFormPropsRequired &
  NoAccountsFormPropsOptional & { message: string }

export const NoAccountsForm = ({
  illustration,
  illustrationAltText,
  illustrationWidth,
  size = 'lg',
  header,
  subHeader,
  isVerticallyCentered = false,
  message,
}: NoAccountsFormProps): React.ReactElement => {
  const isSmall = size === 'sm'
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(noAccountsFormSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
    delayError: 200,
  })
  const toast = useToast()
  const [showNoAccountState, setShowNoAccountState] = useState(false)
  const { handleNextAuthSignOut } = useAuth()
  const company = useCompany()
  const { data: locationsData } = useCashBidsLocations()
  const locations = locationsData?.data
  const reportFailedLogin = useFailedLoginReporting()

  const onSubmit = async (formData: FormValues) => {
    await reportFailedLogin(formData.name, Number(formData.location))
    toast({
      title: 'Success',
      description: `Your information was sent to ${company?.company_name}.`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
    setShowNoAccountState(true)
  }

  if (showNoAccountState) {
    return (
      <NonIdealStates.NoAccountState
        subHeader={message}
        action={
          <Button variant="primary" w={100} onClick={handleNextAuthSignOut}>
            Sign out
          </Button>
        }
      />
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }} noValidate>
      <VStack
        alignItems="center"
        spacing={isSmall ? null : 8}
        mt={isVerticallyCentered ? '15vh' : undefined}
      >
        <Img
          src={`/img/illustrations/${illustration}.svg`}
          width={illustrationWidth ?? (isSmall ? '150px' : ['260px', null, '450px'])}
          alt={illustrationAltText}
          mb={isSmall ? 6 : null}
        />

        <Heading px={isSmall ? null : [2, null, 0]} textAlign="center" type={isSmall ? 'h6' : 'h3'}>
          {header}
        </Heading>

        <Text maxW="420px" px={[2, null, 0]} textAlign="center">
          {subHeader}
        </Text>

        <FormControl isInvalid={!!errors.name} maxW="420px" isRequired>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input isRequired id="name" {...register('name')} />
          <AnimatedFormErrorMessage message={errors.name?.message} />
        </FormControl>

        <FormControl isInvalid={!!errors.location} maxW="420px" isRequired>
          <FormLabel htmlFor="location">Location</FormLabel>
          <Select placeholder=" " id="location" isRequired {...register('location')}>
            {locations?.map((location) => (
              <option key={location.location_id} value={location.location_id}>
                {location.location_name}
              </option>
            ))}
          </Select>
          <AnimatedFormErrorMessage message={errors.location?.message} />
        </FormControl>

        <Button variant="primary" w={100} type="submit">
          Submit
        </Button>
      </VStack>
    </form>
  )
}
