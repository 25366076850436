import { insecureClientStorage } from 'src/utils/storage/insecure/insecure-client-storage'
import { StorageKey } from 'src/utils/storage/storage-key'

const key: StorageKey = 'bwp-anonymous-group-id'

export function getAnonymousGroupId(): Promise<string> {
  return insecureClientStorage.get(key)
}

export async function setAnonymousGroupId(groupId: string): Promise<void> {
  await insecureClientStorage.set(key, groupId)
}
