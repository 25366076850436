import { aggregatorClient } from './client'

export const AGGREGATOR_AUTH_CONFIG_BASE = 'api/aggregator/auth'

export const authRequests = {
  getActiveUsersFromIdentifiers: (ids: string[]) =>
    aggregatorClient
      .post(`${AGGREGATOR_AUTH_CONFIG_BASE}/GetActiveUsersFromIdentifiers`, {
        json: {
          userIdpIds: ids,
        },
      })
      .json<GetActiveUsersFromIdentifiersResponse>(),
}
