import {
  BushelWalletLogo,
  forwardRef,
  IconButton,
  IconButtonProps,
  WalletIcon,
} from 'src/components/designsystem'
import useGoToBushelWallet from 'src/utils/use-go-to-bushel-wallet'

type BushelWalletButtonProps = Readonly<
  Partial<IconButtonProps> & {
    isExpanded?: boolean
    showBorder?: boolean
  }
>

/**
 * ForwardRef is needed for the IconButton to work with the Tooltip
 * @returns Bushel Wallet Button
 */
const BushelWalletButton = forwardRef<BushelWalletButtonProps, typeof IconButton>((props, ref) => {
  const { isLoading, goToBushelWallet } = useGoToBushelWallet({
    isDisabled: props.isDisabled,
  })

  return (
    <IconButton
      ref={ref}
      minW="unset"
      w={props.isExpanded ? '13rem' : 12}
      variant="outline"
      isLoading={isLoading}
      borderColor={props.showBorder ? 'gray.300' : 'transparent'}
      onClick={() => {
        goToBushelWallet()
      }}
      aria-label="Bushel Wallet Link"
      _hover={{ bg: 'gray.100' }}
      _focus={{ bg: 'gray.300' }}
      _active={{ bg: 'gray.300' }}
      _disabled={{
        _hover: { bg: 'gray.50' },
        cursor: 'not-allowed',
      }}
      icon={
        props.isExpanded ? (
          <BushelWalletLogo w="172px" h="24px" />
        ) : (
          <WalletIcon w="32px" h="24px" />
        )
      }
      {...props}
    />
  )
})

export default BushelWalletButton
