import { useMemo } from 'react'
import { useBreakpoint } from 'src/components/designsystem'
import { useDetailModal, FormattedDateRange } from 'src/components/resource'
import { DisplayConfig } from 'src/components/designsystem/display-config'
import Contract from './Contract'
import ContractDetail from './ContractDetail'
import ContractHeader from './ContractHeader'
import {
  ContractFilters,
  APIContractFilterOptions,
  ContractFiltersForQuery,
} from './ContractFilters'
import ContractDetailModal from './ContractDetailModal'
import PriceAmendments from './PriceAmendments'
import { SmallProgressBar } from './contract-visuals'
import { useContracts, useContractFilterOptions, getPagedQueryTotalCount } from 'src/data/queries'
import { useConfig } from 'src/data/config'
import { useFilters } from 'src/data/filters'
import { FLAG_KEYS, useFlagr, useGlobalFlag } from 'src/utils/flagr'
import { PdfViewLink } from 'src/components/resource/PdfViewLink'
import { useAuth } from 'src/auth'
import { useContractsExportsAndReports } from 'src/components/contracts/ExportModal'

export {
  Contract,
  ContractDetail,
  ContractHeader,
  ContractFilters,
  ContractDetailModal,
  PriceAmendments,
}

export const columnConfig: DisplayConfig<Contract> = {
  desktopTemplateColumns: (items) => `repeat(${items.length - 1}, 1fr) 15%`,
  items: [
    { label: 'Contract', key: 'display_id' },
    { label: 'Commodity', key: 'crop_name' },
    {
      label: 'Delivery',
      getValue: ({ data }) =>
        data.delivery_period_value || data.delivery_period_start || data.delivery_period_end,
      renderValue: ({ data }) =>
        data.delivery_period_value ?? (
          <FormattedDateRange
            start={data.delivery_period_start}
            end={data.delivery_period_end}
            localize={false}
          />
        ),
    },
    { label: 'Cash Price', key: 'bid', textAlign: 'right' },
    { label: 'QTY Contracted', key: 'quantity_contracted', textAlign: 'right' },
    { label: 'Contract Type', key: 'contract_type' },
    { label: 'Status', key: 'status' },
    {
      label: 'Remaining',
      getValue: () => true,
      renderValue: ({ data }) => <SmallProgressBar contract={data} />,
    },
  ],
}

export function usePage() {
  const { slug, user } = useAuth()
  const { isDesktop, isMobile } = useBreakpoint()
  const { config, contracts: contractsConfig } = useConfig()
  const detailModal = useDetailModal<Contract>()
  const filterQuery = useContractFilterOptions()
  const userEmail = user?.email_addresses?.data?.find((email) => email.verified)?.email
  const userPhone = user?.phone_numbers?.data?.find((phone) => phone.verified)?.phone

  const {
    filterForQuery,
    isFiltered,
    selectedFilters,
    setAllSelectedFilters,
    setFilterById,
    isFiltersValidated,
  } = useFilters<APIContractFilterOptions, ContractFiltersForQuery>({
    acceptedFilterKeys: [
      'contracted_locations',
      'dates',
      'crops',
      'crop_years',
      'accounts',
      'delivery_locations',
      'detailed_status',
    ],
    filterOptions: filterQuery.data,
  })
  const { query: itemQuery, data: items } = useContracts({
    filter: filterForQuery,
  })
  const accountIdMapping = contractsConfig.listAccountIdMapping()
  const isCompanyPdfEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_CONTRACTS_PDF)
  const isScoularUserEnabled = useFlagr(FLAG_KEYS.WEBAPP_CONTRACTS_PDF_USER_ENABLED, {
    defaultValue: false,
    context: {
      email: userEmail ?? null,
      phone: userPhone ?? null,
    },
  })
  const isPdfEnabled = isCompanyPdfEnabled || isScoularUserEnabled

  const totalItemCount = getPagedQueryTotalCount(itemQuery)

  const { dataExport } = useContractsExportsAndReports({
    slug,
    filterForQuery,
  })

  const isExportDisabled = useMemo(() => {
    return !itemQuery.isSuccess || totalItemCount === 0
  }, [itemQuery.isSuccess, totalItemCount])

  return {
    // Queries + Data
    itemQuery,
    items,
    // Filters
    filterQuery,
    isFiltered,
    selectedFilters,
    setAllSelectedFilters,
    setFilterById,
    isFiltersValidated,
    // Modals
    detailModal,
    dataExport,
    isExportDisabled,
    // Configs
    config,
    columnConfig: useMemo(() => {
      const newConfigItems = [...columnConfig.items]

      // Configurable field (account id mapping) comes from company config settings
      if (accountIdMapping?.label && accountIdMapping?.source_attribute) {
        newConfigItems.splice(3, 0, {
          label: accountIdMapping?.label || '',
          key: 'configurable_field_value',
        })
      }
      if (!isPdfEnabled || !isDesktop) {
        return { ...columnConfig, items: newConfigItems }
      }

      return {
        ...columnConfig,
        desktopTemplateColumns: (items) => `repeat(${items.length - 1}, 1fr) 15% 24px`,
        items: newConfigItems,
      }
    }, [accountIdMapping?.source_attribute, accountIdMapping?.label, isPdfEnabled, isDesktop]),
    actionMenuConfig: useMemo(() => {
      if (!isPdfEnabled) return []

      return [
        {
          key: 'pdf-download',
          render: (item: Contract) => {
            return (
              <PdfViewLink isActionMenu={!isMobile} item={item} itemType="contract" slug={slug} />
            )
          },
        },
      ]
    }, [isPdfEnabled, isMobile, slug]),
  }
}
