import { Fragment, PropsWithChildren, useEffect, useState } from 'react'
import { UnselectedPage } from 'src/components/merch-access/UnselectedPage'
import { useMerchandiser } from 'src/data/merchandiser'
import { Center, GenericNonIdealState, Spinner } from 'src/components/designsystem'
import { ErrorState } from 'src/components/resource'
import * as api from 'src/api'
import { useAuth } from 'src/auth'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'

export function MerchandiserPageCheck({ children }: PropsWithChildren<unknown>) {
  const { hasPermission, isStaff } = useAuth()
  const { isEnabled, userId, selectedUserQuery, selectedAccountsQuery, selectedUser, isUnknownId } =
    useMerchandiser()
  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  const hasDataViewerPermission = hasPermission('data_viewer')

  // No permission -- `Request Access` state takes precedence
  if (isStaffFlagEnabled && isStaff && !hasDataViewerPermission) return <RequestAccessState />

  if (isEnabled && userId && (selectedUserQuery.isFetching || selectedAccountsQuery.isFetching)) {
    return <LoadingState />
  }
  if (isEnabled && userId && (selectedUserQuery.isError || selectedAccountsQuery.isError)) {
    return <ErrorState />
  }
  if ((isEnabled && !userId) || (isEnabled && isUnknownId)) {
    return <UnselectedPage />
  }

  return (
    <PageCheckWithHeader selectedUser={selectedUser} userId={userId} isEnabled={isEnabled}>
      {children}
    </PageCheckWithHeader>
  )
}

export function MerchandiserUnsupportedFeaturePageCheck({
  children,
  message,
}: PropsWithChildren<{ message: string }>) {
  const { hasPermission, isStaff } = useAuth()
  const { isEnabled, userId, selectedUser } = useMerchandiser()
  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  const hasDataViewerPermission = hasPermission('data_viewer')

  // No permission -- `Request Access` state takes precedence
  if (isStaffFlagEnabled && isStaff && !hasDataViewerPermission) return <RequestAccessState />
  if (isEnabled) return <MerchandiserError message={message} />

  return (
    <PageCheckWithHeader selectedUser={selectedUser} userId={userId} isEnabled={isEnabled}>
      {children}
    </PageCheckWithHeader>
  )
}

interface PageCheckWithHeaderProps {
  userId: string
  isEnabled: boolean
  selectedUser: SearchUser
}

function PageCheckWithHeader({
  userId,
  isEnabled,
  selectedUser,
  children,
}: PropsWithChildren<PageCheckWithHeaderProps>) {
  const [isHeaderSet, setIsHeaderSet] = useState(!userId)
  const actualUserId = selectedUser?.userId ? String(selectedUser?.userId) : null

  useEffect(() => {
    if (!isEnabled || !actualUserId) return

    api.clearCentreAppHeaderImpersonation()
    api.clearReportingServiceClientHeaderImpersonation()
    api.clearPaymentsServiceHeaderImpersonation()

    api.updateCentreAppHeader('X-Impersonate-Id', actualUserId)
    api.updateReportingServiceClientHeader('X-Impersonate-Id', actualUserId)
    api.updatePaymentsServiceHeader('X-Impersonate-Id', actualUserId)

    setIsHeaderSet(true)
  }, [isEnabled, actualUserId])

  if (!isHeaderSet) return <LoadingState />

  // When the selected user changes, the `key` here will cause the page to un-mount and re-mount
  return <Fragment key={userId || 'grower'}>{children}</Fragment>
}

function LoadingState() {
  return (
    <Center>
      <Spinner />
    </Center>
  )
}

function MerchandiserError({ message }) {
  return (
    <GenericNonIdealState
      isVerticallyCentered
      illustration="tumbleweed"
      header="Sorry, this page is disabled."
      subHeader={message}
    />
  )
}

function RequestAccessState() {
  return (
    <ErrorState
      isVerticallyCentered
      header="Request Access"
      subHeader="Please reach out to your administrator for access."
    />
  )
}
