import { futuresServiceClient } from './client'

export const futuresRequests = {
  futures: async ({ limit, crop }: { limit?: number; crop?: string }) => {
    let searchParams = null

    if (limit) {
      searchParams = { ...searchParams, limit }
    }

    if (crop) {
      searchParams = { ...searchParams, crop }
    }

    return futuresServiceClient.get('api/v1/futures', { searchParams }).json<FuturesResponse>()
  },
}
