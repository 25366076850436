import { useRouter } from 'next/router'
import { useCustomerSearchDisclosure } from 'src/components/admin/crm/customer-search/CustomerSearchDisclosure'
import StaffUserSearchInput from 'src/components/admin/staff-user-search/StaffUserSearchInput'
import CustomerSearchBarButton from 'src/components/layout/header-buttons/CustomerSearchBarButton'
import { useAppData } from 'src/data'
import { useMerchandiser } from 'src/data/merchandiser'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'

export default function CustomerSearchBar() {
  const {
    isNotAuthenticatedOrErrored,
    isCrmCustomerSearchEnabled,
    isDirectReceivableCustomerSearchEnabled,
    isMerchandiserCustomerSearchEnabled,
  } = useCustomerSearchBar()

  if (isNotAuthenticatedOrErrored) return null

  if (isCrmCustomerSearchEnabled) {
    return <CRMCustomerSearchButton />
  }

  if (isDirectReceivableCustomerSearchEnabled) {
    return <StaffUserSearchInput size="md" inputProps={{ autoFocus: undefined }} />
  }

  if (isMerchandiserCustomerSearchEnabled) {
    return <MerchandiserCustomerSearchButton />
  }

  return null
}

export function useCustomerSearchBar() {
  const { pathname } = useRouter()
  const { auth } = useAppData()
  const { isEnabled: isMerchandiserEnabled } = useMerchandiser()
  const isDirectReceivableEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_DIRECT_RECEIVABLE)

  const isNotAuthenticatedOrErrored = !auth.authenticated || !!auth?.user?.error_message

  const isCrmCustomerSearchEnabled = pathname === '/[company]/staff/crm'
  const isDirectReceivableCustomerSearchEnabled =
    isDirectReceivableEnabled && pathname.startsWith('/[company]/staff/payments/receivable')
  const isMerchandiserCustomerSearchEnabled =
    isMerchandiserEnabled && !pathname.startsWith('/[company]/staff')

  const isCustomerSearchBarEnabled =
    isCrmCustomerSearchEnabled ||
    isDirectReceivableCustomerSearchEnabled ||
    isMerchandiserCustomerSearchEnabled

  return {
    isNotAuthenticatedOrErrored,
    isCrmCustomerSearchEnabled,
    isDirectReceivableCustomerSearchEnabled,
    isMerchandiserCustomerSearchEnabled,
    isCustomerSearchBarEnabled,
  }
}

function CRMCustomerSearchButton() {
  const { openSearch } = useCustomerSearchDisclosure()
  return <CustomerSearchBarButton onClick={openSearch} />
}

function MerchandiserCustomerSearchButton() {
  const { openSearch } = useMerchandiser()
  return <CustomerSearchBarButton onClick={openSearch} />
}
