import { memo, useCallback, useMemo } from 'react'
import create from 'zustand'
import { combine } from 'zustand/middleware'
import { StackingFloatingModal } from 'src/components/resource/StackingFloatingModal'
import { ContractDetailWithData } from 'src/components/contracts/ContractDetail'
import { ContractDetailWithDataV3 } from 'src/components/contracts/contracts-v3/ContractDetail'
import { SettlementDetailWithData } from 'src/components/settlements/SettlementDetail'
import { TicketDetailWithData } from 'src/components/tickets/TicketDetail'
import { InvoiceDetailWithData } from 'src/components/invoices/InvoiceDetail'
import { trackEvent } from 'src/utils/analytics'
import { useConfig } from 'src/data/config'
import getClient from 'src/utils/clients/get-client'
import { BackButtonBehavior } from './BackButtonBehavior'
import { BackStackItem } from 'src/utils/clients/native/android-back-stack'
import { TicketApplication } from 'src/types/tickets/TicketApplication'
import { AssociatedContract } from 'src/types/tickets/AssociatedContract'

type StackedItemEntry =
  | TicketStackedEntry
  | ContractStackedEntry
  | SettlementStackedEntry
  | InvoiceStackedEntry

export type TicketStackedEntry = {
  type: 'ticket'
  item:
    | ContractTicketApplication
    | ContractV3TicketApplication
    | SettlementTicket
    | SettlementAssociatedTicket
}
export type ContractStackedEntry = {
  type: 'contract'
  item: TicketApplication | SettlementAssociatedContract | AssociatedContract
}

export type SettlementStackedEntry = {
  type: 'settlement'
  item: Settlement | AssociatedSettlement | ExternalPaymentAssociatedSettlement
}

export type InvoiceStackedEntry = {
  type: 'invoice'
  item: ExternalPaymentAssociatedInvoice
}

export const useStackedDetailStore = create(
  combine({ stack: [] }, (set, get) => ({
    setItem: (entry: StackedItemEntry) => {
      const { stack } = get()
      set({ stack: [...stack, entry] })
    },

    setToLastItem: () => {
      const { stack } = get()
      set({ stack: stack.slice(0, -1) })
    },

    reset: () => {
      set({ stack: [] })
    },
  }))
)

export function useStackedDetailModal() {
  return useStackedDetailStore((state) => ({
    ...state,
    currentItem: state.stack.length > 0 ? state.stack.slice(-1)[0] : null,
  }))
}

const StackingDetailModal = memo(function ({ trackingCategory }: { trackingCategory: string }) {
  const { currentItem, setToLastItem, reset, stack } = useStackedDetailModal()
  const { contracts } = useConfig()
  const contractsVersion = contracts.contractsVersion()
  const isAndroid = getClient().isAndroid

  const backStackItems = useMemo(
    () => stack.map<BackStackItem>(() => ({ callback: setToLastItem })),
    [stack, setToLastItem]
  )

  const onClose = useCallback(() => {
    reset()
    trackEvent(trackingCategory, 'Associated Ticket Detail Close')
  }, [reset, trackingCategory])

  return (
    <>
      {isAndroid && <BackButtonBehavior backStackItems={backStackItems} />}
      <StackingFloatingModal
        isOpen={!!currentItem}
        primaryTitle={getTitle(currentItem)}
        backAction={setToLastItem}
        onClose={onClose}
      >
        {currentItem?.type === 'ticket' && <TicketDetailWithData id={getId(currentItem)} />}
        {currentItem?.type === 'contract' && contractsVersion !== '3.0.0' && (
          <ContractDetailWithData id={getId(currentItem)} />
        )}
        {currentItem?.type === 'contract' && contractsVersion === '3.0.0' && (
          <ContractDetailWithDataV3 id={getId(currentItem)} />
        )}
        {currentItem?.type === 'settlement' && <SettlementDetailWithData id={getId(currentItem)} />}
        {currentItem?.type === 'invoice' && (
          <InvoiceDetailWithData id={getId(currentItem)} isOpen />
        )}
      </StackingFloatingModal>
    </>
  )
})

export default StackingDetailModal

function isTicketApplication(
  item: TicketApplication | SettlementAssociatedContract | AssociatedContract
): item is TicketApplication {
  return (
    (item as TicketApplication).remote_contract_id !== undefined &&
    (item as TicketApplication).contract_id !== undefined
  )
}

function getTitle(entry: StackedItemEntry) {
  if (entry?.type === 'ticket') {
    return `Ticket ${getTicketRemoteId(entry)}`
  }

  if (entry?.type === 'contract') {
    return `Contract ${
      isTicketApplication(entry.item) ? entry.item?.remote_contract_id : entry?.item.display_id
    }`
  }

  if (entry?.type === 'settlement') {
    return `Settlement ${getSettlementRemoteId(entry)}`
  }

  if (entry?.type === 'invoice') {
    return `Invoice ${'display_id' in entry.item && entry.item?.display_id}`
  }

  return null
}

function getTicketRemoteId(entry: TicketStackedEntry) {
  if ('ticket_id' in entry.item) return entry.item?.remote_ticket_id
  if ('display_id' in entry.item) return entry.item?.display_id
  return entry.item?.remote_id
}

function getSettlementRemoteId(entry: SettlementStackedEntry) {
  if ('display_id' in entry.item) return entry.item?.display_id
  if ('settlementNumber' in entry.item) return entry.item?.settlementNumber
  return ''
}

function getId(entry: StackedItemEntry) {
  if (entry?.type === 'ticket') {
    return 'ticket_id' in entry.item ? entry.item?.ticket_id : entry.item?.id
  }

  if (entry?.type === 'contract') {
    return isTicketApplication(entry.item) ? entry.item?.contract_id : entry.item.id
  }

  if (entry?.type === 'settlement' || entry?.type === 'invoice') {
    return entry.item?.id
  }

  return null
}
