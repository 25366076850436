import getClient from 'src/utils/clients/get-client'
import { insecureBrowserStorage } from 'src/utils/storage/insecure/browser/insecure-browser-storage'
import { insecureNativeStorage } from 'src/utils/storage/insecure/native/insecure-native-storage'
import { StorageKey } from 'src/utils/storage/storage-key'

export const insecureClientStorage = {
  get,
  set,
}

function get<T>(key: StorageKey): Promise<T | undefined> | undefined {
  if (getClient().isNativeApp) {
    return insecureNativeStorage.get(key)
  } else {
    return insecureBrowserStorage.get(key)
  }
}

function set<T>(key: StorageKey, value: T): Promise<void> {
  if (getClient().isNativeApp) {
    return insecureNativeStorage.set(key, value)
  } else {
    insecureBrowserStorage.set(key, value)
    return Promise.resolve()
  }
}
