import { PropsWithChildren, useRef, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Flex,
  useBreakpointHelperSingleton,
  useBreakpoint,
  FlexProps,
  forwardRef,
} from 'src/components/designsystem'
import { UserSearchModalWrapper } from 'src/components/merch-access'
import { useMerchandiser } from 'src/data/merchandiser'
import { Header } from 'src/components/layout/Header'
import { useAppData } from 'src/data'
import {
  Sidebar,
  usePinnedSidebar,
  useMakeSidebarGroups,
  useStaffSidebarGroups,
} from 'src/components/layout/sidebar'
import ESignReminderModal, { useEsignReminder } from 'src/components/esign/ESignReminderModal'
import { getRouteFacts } from 'src/app'
import NotificationEnrollment from 'src/components/push-notifications/enrollment/NotificationEnrollment'
import { getNotificationEnrollmentStatus } from 'src/components/push-notifications/enrollment/get-notification-enrollment-status'
import getClient from 'src/utils/clients/get-client'
import { nativeStatusBar } from 'src/utils/clients/native/native-status-bar'
import { useIsStaffPage } from 'src/utils'

export interface LayoutProps {
  initBreakpoint?: string
}

export function Layout({ initBreakpoint, children }: PropsWithChildren<LayoutProps>) {
  const router = useRouter()
  const { isNotificationsOnboardRoute } = getRouteFacts(router.route)
  const { auth, cookies, activeItem, ...config } = useAppData()
  const { slug } = auth
  const merchandiser = useMerchandiser()
  const { userId } = merchandiser
  const { isPinned, setIsPinned, sidebarTriggerRef } = usePinnedSidebar({ cookies })
  const { breakpoint } = useBreakpoint()

  useBreakpointHelperSingleton({ initBreakpoint })
  useCloseMobileNavDrawer(setIsPinned)

  const isStaffPage = useIsStaffPage()
  const eSignReminder = useEsignReminder()
  const { mainElementRef } = useNavScrollToTop()
  const staffSidebarGroups = useStaffSidebarGroups()
  const customMenuLinks = useMemo(() => config.customMenuLinks() ?? [], [config])
  const showNotificationEnrollment = useShowNotificationEnrollment()

  const userSidebarGroups = useMakeSidebarGroups({
    slug,
    selectedUserId: userId,
    badgeCounts: eSignReminder.badgeCounts,
    customMenuLinks: customMenuLinks,
  })

  const sidebarGroups = useMemo(
    () => (isStaffPage ? staffSidebarGroups : userSidebarGroups),
    [isStaffPage, staffSidebarGroups, userSidebarGroups]
  )

  useEffect(() => {
    styleNativeStatusBarIfNeeded()
  }, [])

  return (
    <Flex
      flexDirection="column"
      w="100vw"
      overflow="hidden"
      bgColor="gray.50"
      css={{
        height: ['100vh', '100svh'],
      }}
    >
      <Header {...{ sidebarTriggerRef, isPinned, setIsPinned }} />
      <Flex
        flexDirection="row"
        w="full"
        bgColor={getContentBackgroundColor(breakpoint, isNotificationsOnboardRoute)}
        overflow="auto"
        flex="1"
        position="relative"
      >
        <Sidebar
          activeItem={activeItem}
          isPinned={isPinned}
          setIsPinned={setIsPinned}
          isStaffPage={isStaffPage}
          groups={sidebarGroups}
        />

        {showNotificationEnrollment && <NotificationEnrollment />}

        {eSignReminder.isFeatureEnabled && !eSignReminder.isESignPage && eSignReminder.isOpen && (
          <ESignReminderModal
            message={eSignReminder.data?.unsigned_document_message}
            onClose={eSignReminder.onClose}
            handleDismiss={eSignReminder.handleDismiss}
            isOpen
          />
        )}

        <MainContent ref={mainElementRef}>{children}</MainContent>
      </Flex>

      {merchandiser.isSearching && <UserSearchModalWrapper />}
    </Flex>
  )
}

function getContentBackgroundColor(
  breakpoint: ChakraBreakpoint,
  isNotificationsOnboardRoute: boolean
) {
  return breakpoint === 'base' && isNotificationsOnboardRoute ? 'white' : 'gray.50'
}

const MainContent = forwardRef<FlexProps, typeof Flex>(({ children }, ref) => {
  return (
    <Flex
      ref={ref}
      as="main"
      id="main"
      position="relative"
      px={[2, null, 6, 8, 12]}
      transitionDuration="faster"
      flexDir="column"
      flex="1"
      overflow="auto"
      pb={['calc(16px + env(safe-area-inset-bottom))', 'calc(32px + env(safe-area-inset-bottom))']}
    >
      {children}
    </Flex>
  )
})

function useNavScrollToTop() {
  const router = useRouter()
  const mainElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      if (mainElementRef?.current?.scrollTop !== 0) {
        mainElementRef.current.scrollTo({ top: 0 })
      }
    }

    router.events?.on('routeChangeComplete', handleRouteChangeComplete)

    // If the component is unmounted, unsubscribe
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router.events])

  return {
    mainElementRef,
  }
}

function useCloseMobileNavDrawer(setIsPinned) {
  const { isDesktop } = useBreakpoint()
  const router = useRouter()
  const isNativeApp = getClient().isNativeApp

  useEffect(() => {
    if (!isDesktop || isNativeApp) setIsPinned(false)
  }, [router.asPath, isDesktop, setIsPinned, isNativeApp])
}

function useShowNotificationEnrollment(): boolean {
  const [isVisible, setIsVisible] = useState(false)

  const updateIsVisible = async () => {
    const isVisible = await getNotificationEnrollmentVisibility()
    setIsVisible(isVisible)
  }

  useEffect(() => {
    updateIsVisible()
  }, [])

  return isVisible
}

async function getNotificationEnrollmentVisibility(): Promise<boolean> {
  if (getClient().isNativeApp) {
    return isNotificationEnrollmentStatusIncomplete()
  } else {
    return false
  }
}

async function isNotificationEnrollmentStatusIncomplete(): Promise<boolean> {
  const status = await getNotificationEnrollmentStatus()
  return status === 'incomplete'
}

function styleNativeStatusBarIfNeeded() {
  if (getClient().isNativeApp) {
    nativeStatusBar.style()
  }
}
