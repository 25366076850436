import { decodeJwt } from 'jose'
import { isServer } from 'src/utils/env'
import { NormalizedOptions } from 'ky'
import { Session } from 'next-auth'
import logger from 'src/utils/logger'

/**
 * Ky client `beforeRequest` handler for refreshing the token if it has expired. Guards
 * against client-side requests that may have an expired token due to inactivity or
 * abnormal session restoration.
 * @param request - The request object from the client
 * @param _options - The options object (not used)
 * @returns void
 * @see centreClient
 */
export default async function handleRefreshTokenIfExpired(
  request: Request,
  _options: NormalizedOptions
) {
  if (getIsHandlerDisabled()) return

  try {
    const requestToken = getRequestToken(request)

    if (!requestToken) return
    if (!isTokenExpired(requestToken)) return

    const newToken = await refreshAuthToken()
    request.headers.set('Authorization', `Bearer ${newToken}`)
  } catch (error) {
    logger.error({ message: 'src/api - failed to handleRefreshTokenIfExpired', error })
  }
}

function getIsHandlerDisabled(): boolean {
  return isServer()
}

function getRequestToken(request: Request): string | undefined {
  if (!request.headers.has('Authorization')) return
  const [, requestToken] = request.headers.get('Authorization').split(' ')
  return requestToken
}

function isTokenExpired(token: string): boolean {
  const { exp: tokenExpiryMs } = decodeJwt(token)
  if (!tokenExpiryMs) throw new Error('Token does not contain an expiration time')
  return Date.now() > tokenExpiryMs * 1000
}

async function refreshAuthToken() {
  const authRes = await fetch('/api/auth/session')
  const authData = (await authRes.json()) as Session

  if (!authRes.ok || !authData.accessToken) {
    throw new Error('Failed to get fresh token from session')
  }

  logger.info({ message: 'src/api - refreshing token before potentially expired request' })

  return authData.accessToken
}
