import { aggregatorClient } from './client'

export const AGGREGATOR_CONFIG_BASE = 'api/aggregator/config'

export const configRequests = {
  getCommoditySubgroups: () =>
    aggregatorClient
      .post(`${AGGREGATOR_CONFIG_BASE}/v1/GetCommoditySubgroups`, { json: {} })
      .json<CommoditySubgroupResponse>(),

  findOrCreateTenantBySlug: () =>
    aggregatorClient
      .post(`${AGGREGATOR_CONFIG_BASE}/v1/FindOrCreateTenantBySlug`, { json: {} })
      .json<FindOrCreateTenantBySlugResponse>(),

  findOrCreateTenantConfigBySlug: () =>
    aggregatorClient
      .post(`${AGGREGATOR_CONFIG_BASE}/v1/FindOrCreateTenantConfigBySlug`, { json: {} })
      .json<FindOrCreateTenantConfigBySlugResponse>(),
}
