import { PropsWithChildren } from 'react'
import { SessionProvider } from 'next-auth/react'
import { getServerSession as getServerSessionReal } from 'next-auth'
import { AuthContext, AuthProvidersProps } from 'src/auth/auth-context'
import { nextAuthSessionStatic } from 'src/__fixtures__/users'
import { ENV } from 'src/utils'

type GetServerSessionReal = typeof getServerSessionReal

export function AuthProviders({
  cookies: _cookies,
  authContext,
  children,
}: PropsWithChildren<AuthProvidersProps>) {
  return (
    <AuthContext.Provider value={authContext}>
      <SessionProvider session={ENV.MOCK_NO_AUTH ? null : nextAuthSessionStatic}>
        {children}
      </SessionProvider>
    </AuthContext.Provider>
  )
}

export const getServerSession: GetServerSessionReal = async (..._args) => {
  return ENV.MOCK_NO_AUTH ? null : (nextAuthSessionStatic as any)
}
