import { aggregatorClient } from 'src/api/aggregator/client'

export const businessPagesBaseUrl = 'api/aggregator/businesspages'

export const businessLocationRequests = {
  getBusinessLocations: (body?: BusinessLocationsRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/GetBusinessLocations`, { json: body })
      .json<BusinessLocationsResponse>(),
  getBusinessLocationDetails: (body: BusinessLocationDetailsRequest) =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/GetBusinessLocationDetails`, { json: body })
      .json<BusinessLocationDetailsResponse>(),
  getBusinessLocationsFilters: () =>
    aggregatorClient
      .post(`${businessPagesBaseUrl}/v1/GetBusinessLocationsFilters`)
      .json<BusinessLocationsFiltersResponse>(),
}
