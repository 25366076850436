import { Fragment } from 'react'
import { HTTPError } from 'src/api'
import { Box, Flex, Divider, Skeleton, Text, useBreakpoint } from 'src/components/designsystem'
import {
  DetailGrid,
  ErrorState,
  SkeleKeyValue,
  SkeleText,
  FormattedDate,
  renderOrEmDash,
} from 'src/components/resource'
import { useSettlementDetail } from 'src/data/queries'
import AssociatedTickets from 'src/components/settlements/AssociatedTickets'
import { AssociatedTicketsViaTicketApps } from './AssociatedTicketsViaTicketApps'
import AssociatedContracts from 'src/components/settlements/AssociatedContracts'
import { useGlobalFlag, FLAG_KEYS } from 'src/utils/flagr'
import { useConfig } from 'src/data/config'

const SECTION_CONFIG = [
  {
    key: 'details',
    component: DetailSection,
    column: { base: 1, sm: 1, md: 1, lg: 1, xl: 1, '2xl': 1 },
  },
  {
    key: 'payment',
    component: PaymentSection,
    column: { base: 1, sm: 1, md: 1, lg: 2, xl: 2, '2xl': 2 },
  },
  {
    key: 'deductions',
    component: DeductionsSection,
    column: { base: 1, sm: 1, md: 2, lg: 2, xl: 2, '2xl': 2 },
  },
  {
    key: 'payouts',
    component: PayoutSection,
    column: { base: 1, sm: 1, md: 2, lg: 3, xl: 3, '2xl': 3 },
  },
]

function getSectionsForColumn({
  columnIndex,
  breakpoint = 'xl',
  isLoaded,
  settlementDetail,
}: {
  columnIndex: number
  breakpoint: ChakraBreakpoint
  isLoaded: boolean
  settlementDetail: SettlementDetail
}) {
  return (
    <Fragment>
      {SECTION_CONFIG.filter((section) => section.column[breakpoint] === columnIndex).map(
        ({ key, component: Component }) => (
          <Component key={key} {...{ isLoaded, settlementDetail }} />
        )
      )}
    </Fragment>
  )
}

interface SettlementDetailProps {
  isLoaded?: boolean
  error?: HTTPError
  settlementDetail: SettlementDetail
}

export default function SettlementDetail({
  isLoaded = true,
  error = null,
  settlementDetail,
}: SettlementDetailProps) {
  const { breakpoint } = useBreakpoint()
  const { getConfigValue, contracts } = useConfig()

  const associatedTicketsSource = getConfigValue('settlement_associated_tickets_association_method')

  const associatedContractsEnabled =
    useGlobalFlag(FLAG_KEYS.WEBAPP_TICKET_APPLICATIONS_V2) &&
    contracts.contractsVersion() === '3.0.0'

  if (error) {
    return (
      <ErrorState
        data-testid="failed-state"
        subHeader={
          error?.response?.payload?.error?.message ||
          "There was an error while retrieving this settlement's details. Please refresh the page to try again."
        }
      />
    )
  }

  return (
    <>
      <DetailGrid.Title>Breakdown</DetailGrid.Title>
      <DetailGrid>
        <DetailGrid.List>
          {getSectionsForColumn({ columnIndex: 1, breakpoint, isLoaded, settlementDetail })}
        </DetailGrid.List>
        <DetailGrid.List>
          {getSectionsForColumn({ columnIndex: 2, breakpoint, isLoaded, settlementDetail })}
        </DetailGrid.List>
        <DetailGrid.List>
          {getSectionsForColumn({ columnIndex: 3, breakpoint, isLoaded, settlementDetail })}
        </DetailGrid.List>
      </DetailGrid>
      {settlementDetail?.comments && (
        <Box mt={[6, null, null, 8]}>
          <DetailGrid.Title>Comments</DetailGrid.Title>
          <Box layerStyle="detail-card">
            <Skeleton isLoaded={isLoaded}>
              {/* {white-space: 'pre-line' respects \n chars that may be in comments for formatting} */}
              <Text whiteSpace="pre-line">{settlementDetail?.comments}</Text>
            </Skeleton>
          </Box>
        </Box>
      )}

      {associatedTicketsSource === 'ticket_applications' ? (
        <Box mt={[6, null, null, 8]}>
          <AssociatedTicketsViaTicketApps settlementId={settlementDetail?.id} />
        </Box>
      ) : (
        settlementDetail?.tickets?.length > 0 && (
          <Box mt={[6, null, null, 8]}>
            <AssociatedTickets
              tickets={settlementDetail?.tickets}
              commodityName={settlementDetail?.commodity_name}
            />
          </Box>
        )
      )}
      {associatedContractsEnabled && <AssociatedContracts settlementId={settlementDetail?.id} />}
    </>
  )
}

function DetailSection({ isLoaded, settlementDetail }) {
  return (
    <DetailGrid.ListCard heading="Details">
      <SkeleKeyValue isLoaded={isLoaded} label="Date">
        {renderOrEmDash({
          value: settlementDetail?.settled_on_date,
          itemToRender: <FormattedDate date={settlementDetail?.settled_on_date} localize={false} />,
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Settled Units">
        {renderOrEmDash({ value: settlementDetail?.settled_quantity })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Account ID">
        {renderOrEmDash({
          value: settlementDetail?.account?.name || settlementDetail?.account?.id,
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Commodity">
        {renderOrEmDash({ value: settlementDetail?.commodity_name })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Payment Status">
        {renderOrEmDash({ value: settlementDetail?.payment_status })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Crop Year">
        {renderOrEmDash({ value: settlementDetail?.crop_year })}
      </SkeleKeyValue>
    </DetailGrid.ListCard>
  )
}

function PaymentSection({ isLoaded, settlementDetail }) {
  return (
    <DetailGrid.ListCard heading="Payment">
      <SkeleKeyValue isLoaded={isLoaded} label="Gross">
        {renderOrEmDash({ value: settlementDetail?.gross_payment })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Adjustments">
        {renderOrEmDash({ value: settlementDetail?.adjusted_payment })}
      </SkeleKeyValue>
      <Divider />
      <Flex
        w="100%"
        justifyContent="space-between"
        minH={['40px', null, '48px']}
        alignItems="center"
      >
        <SkeleText isLoaded={isLoaded} fontWeight="bold" pr={2}>
          Net
        </SkeleText>
        <SkeleText isLoaded={isLoaded} label="Net" fontWeight="bold" textAlign="right">
          {settlementDetail?.net_payment}
        </SkeleText>
      </Flex>
    </DetailGrid.ListCard>
  )
}

function PayoutSection({ isLoaded, settlementDetail }) {
  if (settlementDetail?.payouts?.length === 0) return null
  return (
    <DetailGrid.ListCard heading="Payouts">
      {settlementDetail?.payouts?.map((payout, i) => (
        <Fragment key={`${payout.type}-${payout.display_id}`}>
          <SkeleKeyValue isLoaded={isLoaded} label="ID">
            {renderOrEmDash({ value: payout.display_id })}
          </SkeleKeyValue>
          <Divider />
          <SkeleKeyValue
            isLoaded={isLoaded}
            label={`Name${payout.payee_names.length > 1 ? 's' : ''}`}
          >
            {renderOrEmDash({ value: payout.payee_names?.join(', ') })}
          </SkeleKeyValue>
          <Divider />
          <SkeleKeyValue isLoaded={isLoaded} label="Amount">
            {renderOrEmDash({ value: payout.amount })}
          </SkeleKeyValue>
          {i < settlementDetail?.payouts.length - 1 && <Box height={8} />}
        </Fragment>
      ))}
    </DetailGrid.ListCard>
  )
}

function DeductionsSection({ isLoaded, settlementDetail }) {
  if (settlementDetail?.payment_adjustments?.length === 0) return null
  return (
    <DetailGrid.ListCard heading="Deductions & Premiums">
      {settlementDetail?.payment_adjustments?.map((adjustment, i) => (
        <Fragment key={i}>
          <SkeleKeyValue isLoaded={isLoaded} label={adjustment.reason}>
            {renderOrEmDash({ value: adjustment.amount })}
          </SkeleKeyValue>
          <Divider />
        </Fragment>
      ))}
      <Flex
        w="100%"
        justifyContent="space-between"
        minH={['40px', null, '48px']}
        alignItems="center"
      >
        <SkeleText isLoaded={isLoaded} fontWeight="bold" pr={2}>
          Total Adjustments
        </SkeleText>
        <SkeleText
          isLoaded={isLoaded}
          label="Total Adjustments"
          fontWeight="bold"
          textAlign="right"
        >
          {settlementDetail?.adjusted_payment}
        </SkeleText>
      </Flex>
    </DetailGrid.ListCard>
  )
}

export function SettlementDetailWithData({ id }: { id: number }) {
  const { isSuccess, error, data } = useSettlementDetail({ id, enabled: true })

  return <SettlementDetail isLoaded={isSuccess} error={error} settlementDetail={data} />
}
